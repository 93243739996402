import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { useState } from 'react';
import {  Twitter} from 'react-bootstrap-icons';
import { Telegram } from 'react-bootstrap-icons';

import dexscreener from "./assets/dexscreener.png"
import coinmarketcap from "./assets/coinmarketcap.png"
import coingecko from "./assets/coingecko.webp"





function App() {
  const [page,setPage] = useState(0);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = useState('0');  // State to keep track of the selected value
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [play,setPlay] = useState(0);
  // eslint-disable-next-line
  const handleChange = (event) => {
      setSelectedValue(event.target.value);  // Update the state when the selected value changes
  }

  const handleSend = () => {
    if (input.trim()) {
      // Add user message
      setPlay(1);
      setMessages(prev => [...prev, { text: input, sender: 'user' }]);
      
      // Automatically add the response after the user's message
      setMessages(prev => [...prev, { text: "Hello, User we are happy that you are trying our AI. We will be in service soon. Stay connected with DogBot.", sender: 'bot' }]);

      setInput('');
    }
  };
  return (
  <>
  <br /> <br />
  <div className='container'>
 <div className='row'>
  <div className='col-sm-4' style={{borderLeft:"5px solid orange"}}>
     <h1 className='fw-bold pointable' onClick={() => setPage(0)}>HOME</h1>
  
     <h1 className='fw-bold pointable' onClick={() => setPage(1)}>ABOUT</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(7)}>TELEGRAM BOT</h1>  


     <h1 className='fw-bold pointable' onClick={() => setPage(9)}>DOGBOT Ai-Chat</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(10)}>ROADMAP</h1>

     <h1><a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=8ygQonfsFzuqS5omS45pbEnuteJVoFKobPp1hY4BT7VY&fixed=in"><button className='btn btn-warning btn-lg'>BUY ON RAYDIUM</button></a></h1>
  </div>

  <div className='col-sm-8'>
    {page === 0? <>
      <h1 style={{fontSize:"100px",textShadow: "2px 2px orange"}}>DOGBOT THE BOT OF THE FUTURE</h1>
    <h3 style={{color:"white",textShadow: "1px 1px white"}}>
   THE TOKEN HAS BEEN STEALTH LAUNCHED ON SOLANA. THE DOGBOT SNIPER IS THE FASTEST AND SAFEST SNIPER ON THE MARKET, UTILIZING AI TECHNOLOGY.
       </h3>
    <a href="https://dexscreener.com/solana/4w8cvf3k854dtjbdvfyksdfrqt2k21luqspvdtdinbcu"><button className='btn btn-warning btn-lg'>Dexscreener</button></a> <a href="https://solscan.io/token/8ygQonfsFzuqS5omS45pbEnuteJVoFKobPp1hY4BT7VY"><button className='btn btn-warning btn-lg'>SolScan</button></a>
    </> : <></>}

    {page === 1? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>ABOUT</h1>
    <h3 style={{color:"white",textShadow: "1px 1px white"}}>DOGBOT FEATURES</h3>
    <h1 className='text-warning fw-bold'>AI</h1>
    <p className='text-white'> ANTI RUG</p>
    <p className='text-white'>Anti-Bot (Protected By FrontRunners bots)</p>
    <p className='text-white'>Simulated Technology Installed</p>
    <p className='text-white'>Avoid High Tax Tokens</p>
    <p className='text-white'>Analyze smart contracts</p>
    <p className='text-white'>Configurated into Telegram</p>
    <p className='text-white'>Honeypot detection</p>
 
    </> : <></>}



    {page === 3? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>PARTNERS</h1>
  <div className='row'>
    
      <div className='col-sm-12'> <img src={dexscreener} width={600} alt="dexscreener" className='img-fluid img-thumbnail' /></div>
      </div>
      <br />
      <div className='row'>
      <div className='col-sm-6'> <img src={coinmarketcap} width={600} alt="coinmarketcap" className='img-fluid img-thumbnail' /></div>
      <div className='col-sm-6'> <img src={coingecko} width={600} alt="coingecko" className='img-fluid img-thumbnail' /></div>
    </div>
  
    </> : <></>}


   

    {page === 9? <>
      <h1 style={{fontSize:"100px",textShadow: "2px 2px orange"}}>DOGBOT-AI-CHAT</h1>
      <div className="card" style={{height:"50%"}}>
        <div className='card-body bg-dark'>
      <div className="messages">
        {play === 0? <><center><h1 className='text-white fw-bold'>Welcome to DOGBOT-AI CHAT</h1></center></> : <>
        {messages.map((msg, index) => (
          <div key={index} className={msg.sender}>
             <span className="icon">{msg.sender === 'user' ? "👤" : "🤖"}</span>
            {msg.text}
          </div>
        ))}
        </>}
       
      </div>
      </div>
     <br />
     <div className='container'>
       <div className='row'>
        <div className='col-sm-10'>
        <input 
          value={input} 
          className='form-control'
          onChange={e => setInput(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && handleSend()}
        />
          </div>
        
        <div className='col-sm-2'>
        <button onClick={handleSend} className='btn btn-success'>Send</button>
        </div>
        </div>
        </div>
       <br />
    
    </div>
    </> : <></>}

       {page === 10? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>ROADMAP</h1>
      <div className='row'>
        <div className='row'>

        <div className='col-sm-12'>
          <div className='card bg-dark'>
            <div className='card-header bg-warning'>
            <h1 className='text-white fw-bold'>
              2024 - January
            </h1>
           
            </div>
            <div className='card-body bg-dark'> 
             <p className='text-white fw-bold'>Multichain (ALL EVM AND SOL CHAINS)</p>
            <p className='text-white fw-bold'>V1.1/V1.2/V1.3 Telegram bot</p>
            <p className='text-white fw-bold'>Chatbot V1 release</p>
            <p className='text-white fw-bold'>WEB application </p>
          </div>
            </div>
          </div>
           


       
    
        </div>
      </div>
    </> : <></>}


    {page === 7? <>
      <h1 style={{textShadow: "2px 2px orange"}}>TELEGRAM (BOT)</h1> 
      <br /> <br />
   <div className='container'>
    <h3 className='text-white'>
     

      FIRST VERSION OF OUR BOT HAS BEEN RELEASED. WE ARE CURRENTLY WORKING AND IMPROVING THE BOT. THE V2 WILL BE RELEASED SOON.
   </h3>
   
  <center><a href="https://t.me/THEOGDOGBOT"><img src="https://logohistory.net/wp-content/uploads/2022/10/Telegram-logo.png"  alt="TELEGRAM BOT" className='img-fluid' width={300}/><br /> TELEGRAM BOT</a></center> <br />
    
   </div>
    </> : <></>}


   

      </div>
 </div>
 <br />
 <center>
  <br /> <br />
<h2 className='text-warning fw-bold'><a href="https://twitter.com/dogbotsol"><Twitter />TWITTER </a> - <a href="https://t.me/dogbotsol"><Telegram /> TELEGRAM</a></h2>
 </center>
 </div>
  </>
  );
}

export default App;
